<template>

<div id="main__content" ref="appDom">
      <div class="page page__home ">
        <IntroData class="" />
    <TheFooter class="footer_section" />

      </div>
    </div>
</template>

<script>
import IntroData from "@/components/common/introblock/IntroData.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";
import { useStore } from 'vuex';
import { onMounted } from 'vue';
export default {
  components: {
    IntroData,
    TheFooter,
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      store.commit("setMainColor", "black")
    })
  }
  
};



</script>
<style
  lang="
    scss"
></style>
